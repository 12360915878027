import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h2></h2>
          <h1 class="ml11">
  <span class="text-wrapper">
    <span class="line line1"></span>
    <span class="letters"></span>
  </span>
</h1>
        </div>
        

      </div>
    );
  }
}

export default App;
